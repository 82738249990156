import React, { useState } from "react";
import { updateActiveUser } from "../../actions/UserAction";
import { A } from "hookrouter";
import { login } from "../../api/Api";
import { fetchFirebaseToken } from "../firebase_notification/FirebaseNotification";
import { Logo } from "../common/AppIcons";

export default function Login() {
  const [password, setPassword] = useState("");
  const [username, setusername] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const toast = (message) => setErrorMessage(message);

  const checkFields = () => {
    if (username === "") {
      setErrorMessage("Please enter username");
    } else if (username.length < 5) {
      setErrorMessage("Username must be at least 5 letters long");
    } else if (password === "") {
      setErrorMessage("Please enter password");
    } else {
      setErrorMessage("");
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    let flag = checkFields();
    if (flag) {
      toast("Please Wait!");
      const payload = {
        usernameOrEmail: username?.trim(),
        password: password?.trim(),
      };
      login(payload)
        .then((response) => {
          localStorage.setItem("USER", username?.trim());
          localStorage.setItem("ACCESS_TOKEN", response.token.accessToken);
          updateActiveUser();
          fetchFirebaseToken();
        })
        .catch((error) => {
          console.log(error);
          toast("Sorry, We were unable to complete your request");
        });
    }
  };

  return (
    <div className="h-full min-h-screen w-full flex flex-row items-center">
      <div className="h-full hidden lg:block">
        <img className="object-fill h-screen" alt="hero" src="/hero_pic.jpg" />
      </div>
      <div className="h-full flex-grow flex flex-col items-center justify-center text-left">
        <div className="flex flex-col items-start max-w-lg lg:mb-20">
          <Logo className="w-24" />
          <h1
            style={{ color: "#211C52", fontSize: "22px" }}
            className="mt-8 title-font font-semibold"
          >
            SquadHub Login
          </h1>

          <form
            noValidate
            onSubmit={(e) => e.preventDefault()}
            className="mt-4 flex w-full flex-col items-start gap-3"
          >
            <div className="w-full">
              <label
                htmlFor="hero-field"
                className="leading-7 text-sm opacity-70"
                style={{ color: "#030229", fontWeight: "550" }}
              >
                Enter Username
              </label>
              <input
                id="username"
                name="username"
                value={username}
                onChange={(e) => setusername(e.target.value)}
                onBlur={checkFields}
                required
                className="w-full bg-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4 border-none"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="hero-field"
                className="leading-7 text-sm opacity-70"
                style={{ color: "#030229", fontWeight: "550" }}
              >
                Your password
              </label>
              <div className="flex flex-row items-center">
                <input
                  type={showPassword ? "text" : "password"}
                  id="Password"
                  name="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={checkFields}
                  required
                  className="w-full bg-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4 border-none"
                />
                {showPassword === false ? (
                  <button
                    type="button"
                    className="text-gray-600 w-0 overflow-visible relative right-8 py-2 rounded-r-md focus:outline-none"
                    onClick={() => setShowPassword(true)}
                  >
                    <svg
                      className="flex h-6 w-6 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="text-gray-600 w-0 overflow-visible py-2 rounded-r-md focus:outline-none"
                    onClick={() => setShowPassword(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="flex h-6 w-6 float-right mr-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
            {errorMessage !== "" && (
              <div className="text-sm text-red-500">{errorMessage}</div>
            )}
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              style={{ backgroundColor: "#605BFF" }}
              className="w-full text-white border-0 py-2 px-6 focus:outline-none hover:opacity-70 rounded text-sm"
            >
              Login
            </button>
          </form>
          <div className="mt-3 flex flex-row items-center gap-2 text-sm">
            <span style={{ color: "#030229" }}>Can't login?</span>
            <A
              href="/forgot-password"
              className="text-blue-600 hover:text-blue-800"
            >
              Forgot Password
            </A>
          </div>
        </div>
      </div>
    </div>
  );
}
